<template>

  <div class="container">
    <div class="row" v-if="!edit">

      <div class="col-12 col-lg-8">
        <h1>Huoltoilmoitukset</h1>
      </div>
      <div class="col-12 col-lg-4 text-right">
        <button class="btn btn-new btn-lg d-inline-block" v-if="!loading" v-on:click.stop.prevent="newitem"><i class="fas fa-plus"></i> Luo uusi huoltotyö</button>
      </div>

      <div class="col-12">

        <b-overlay v-if="loading" class="list-loading" variant="white" show no-wrap></b-overlay>

				<div class="filters">
          <button type="button" class="btn btn-sm btn-primary mt-3 float-right" v-on:click="load()">Suodata</button>
          <div class="filter">
            <multiselect v-model="filters.status" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Valitse tila" :options="statuses.map(status => status.id)" :custom-label="opt => statuses.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">tila</span><span v-if="values.length > 1">tilaa</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Tiloja ei löydetty.</template>
            </multiselect>
          </div>
          <div class="filter">
            <multiselect v-model="filters.site" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Kohteet" :options="sites.map(site => site.id)" :custom-label="opt => sites.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">kohde</span><span v-if="values.length > 1">kohdetta</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Kohteita ei löydetty.</template>
            </multiselect>
          </div>
          <div class="filter">
            <multiselect v-model="filters.type" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Tyypit" :options="types.map(type => type.id)" :custom-label="opt => types.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">tyyppi</span><span v-if="values.length > 1">tyyppiä</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Tyyppejä ei löydetty.</template>
            </multiselect>
          </div>
          <div class="filter">
            <multiselect v-model="filters.area" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :showLabels="false" placeholder="Alue" :options="areas.map(area => area.id)" :custom-label="opt => areas.find(x => x.id == opt).name">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} <span v-if="values.length == 1">alue</span><span v-if="values.length > 1">aluetta</span></span></template>
              <template slot="tag">{{ '' }}</template>
              <template slot="noResult">Alueita ei löydetty.</template>
            </multiselect>
          </div>
          <div style="clear:both"></div>
          <div class="filter">
            <select v-model="filters.assigned_to">
              <option value="">Työntekijä</option>
              <option v-for="(employee) in employees" :key="employee.id" :value="employee.id">{{ employee.fullname }}</option>
            </select>
          </div>
          <div class="filter">
            <select v-model="filters.site_entry_date">
              <option value="">Käynnin ajankohta</option>
              <option value="today">Tänään</option>
              <option value="week">Seuraavat 7 päivää</option>
            </select>
          </div>
          <div class="filter">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="checkup" value="1" v-model="filters.checkup">
              <label class="custom-control-label" for="checkup">Kuittausväli lähestyy</label>
            </div>
          </div>
				</div>

				<b-alert variant="danger" :show="error? true : false">{{ error }}</b-alert>

				<table class="admin-list" v-if="!loading && visible > 0">
					<tr>
						<th>PVM <i class="fas fa-chevron-down" v-on:click="sort = 'created:asc'" v-if="sort != 'created:asc'"></i> <i class="fas fa-chevron-up" v-on:click="sort = 'created:desc'" v-if="sort != 'created:desc'"></i></th>
						<th>Asukas, kohde</th>
						<th>Otsikko</th>
						<th>Viim.muutos</th>
            <th>Osoitettu</th>
						<th>Kuittausväli</th>
						<th></th>
					</tr>
					<tr v-for="(item) in items" :key="item.id" :id="['ticket-' + item.id]">
						<td class="w120"><p><span class="date">{{ item.created | formatDate }}</span><span class="status" :class="[ 'status-' + item.status ]">{{ getStatus(item.status) }}</span></p></td>
						<td class=""><p><router-link :to="{ name: 'Asukkaat', query: { id: item.created_by } }">{{ item.site_resident }}</router-link><br/><strong><router-link :to="{ name: 'Kohteet', query: { id: item.site_id } }">{{ item.site_name }}</router-link></strong></p></td>
						<td class="text"><p><strong>{{ item.title }}</strong></p></td>
						<td class="text"><p>{{ item.updated_by_name }}<br/>{{ item.updated | formatDateTime }}</p></td>
            <td><p>{{ item.assigned_to_name }}</p></td>
						<td><p v-if="item.status == 4" :class="[ item.next_check_days < 7 ? 'check-now' : '']"><strong>{{ item.next_check_days }} pvä</strong><br/>jäljellä</p></td>
						<td class="text-right"><a class="btn btn-sm btn-primary" v-on:click.stop.prevent="edititem(item.id)">Näytä</a></td>
					</tr>
				</table>

				<b-alert class="my-3" variant="info" :show="!loading && items.length == 0">Huoltotöitä ei löydetty!</b-alert>

				<ul class="pagination pagination-sm justify-content-center my-4" v-if="!loading && pages > 0">
					<li v-for="n in pages" :key="n" class="page-item" :class="[page == (n-1) ? 'active' : '']"><a class="page-link" href="#" v-on:click.stop.prevent="showpage(n-1)">{{ n }}</a></li>
				</ul>

      </div>
    </div>

    <div class="row" v-if="edit">
			<div class="col-12 pt-1">
        <b-overlay variant="white" :show="sending" no-wrap></b-overlay>
        <b-overlay variant="white" :show="uploading" no-wrap>
          <template #overlay>
            <div class="text-center text-dark p-4">
              <div class="mb-3">Siirretään tiedostoja...</div>
                <b-progress
                  min="1"
                  max="100"
                  :value="progress"
                  variant="success"
                  height="10px"
                  class="mx-n4 rounded-0"
                ></b-progress>
              </div>
            </template>
        </b-overlay>
        <form @submit.prevent="saveedit">
					<div class="row">
						<div class="col-12 col-lg-6">
              <h1 v-if="typeof edit_data.id == 'undefined'">Uusi huoltotyö</h1>
              <h1 v-if="typeof edit_data.id != 'undefined'"><span class="id">#{{ edit_data.id }}</span> <span class="date">{{ edit_data.created | formatDateTime }}</span> <span class="site">{{ edit_data.site_resident }}<br/><strong>{{ getSite( edit_data.site_id ) }}</strong></span></h1>

							<div class="form-group row" v-if="typeof edit_data.id == 'undefined'">
                <div class="col-6 col-lg-4 pt-2">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="recurring" value="1" v-model="edit_data.recurring">
                    <label class="custom-control-label" for="recurring">Toistuva tiketti</label>
                  </div>
                </div>
                <div class="col-4 col-lg-3" v-if="edit_data.recurring == 1">
                  <input type="number" class="form-control" v-model="edit_data.recurring_interval" />
                </div>
                <div class="col-2 col-lg-3 pt-2" v-if="edit_data.recurring == 1">
                  <label class="small">päivän välein</label>
                </div>

                <div class="form-group col-12 pt-2" v-if="edit_data.recurring == 1">
                  <label class="form-label">Ensimmäinen suorituspäivä</label>
                  <input type="datetime-local" v-model="edit_data.recurring_start" class="form-control" />
                </div>
							</div>

              <div class="form-group col-lg-8 px-0">
								<label class="form-label">Työn tyyppi</label>
                <select v-model="edit_data.type" class="form-control" required>
                  <option value="">- valitse tyyppi -</option>
                  <option v-for="(type) in types" :key="type.id" :value="type.id">{{ type.name }}</option>
                </select>
							</div>

              <div class="form-group col-lg-8 px-0">
								<label class="form-label">Osoita työntekijälle</label>
                <select v-model="edit_data.assigned_to" class="form-control" >
                  <option value="0">- valitse työntekija -</option>
                  <option v-for="(employee) in employees" :key="employee.id" :value="employee.id">{{ employee.fullname }}</option>
                </select>
							</div>

							<div class="form-group">
								<label class="form-label">Otsikko</label>
                <input type="text" v-model="edit_data.title" class="form-control" required />
							</div>

              <div class="form-group">
								<label class="form-label">Kuvaus</label>
                <textarea v-model="edit_data.description" class="form-control" required></textarea>
							</div>

              <div class="form-group row">

                <div class="col-12 col-lg-5">
                  <label class="form-label">Valitse kohde</label>
                  <select class="form-control" v-model="edit_data.site_id" required>
                    <option value=""> - valitse kohde - </option>
                    <option v-for="(site) in sites" :key="site.id" :value="site.id">{{ site.name }}</option>
                  </select>
                </div>

                <div class="col-12 col-lg-3">
                  <label class="form-label">Asunto</label>
                  <input type="text" v-model="edit_data.site_address" class="form-control" />
                </div>

                <div class="col-12 col-lg-4">
                  <label class="form-label">Kohteen tarkenne</label>
                  <input type="text" v-model="edit_data.site_description" class="form-control" />
                </div>

              </div>

              <div class="form-group row">
                <div class="col-12">
                  <label class="form-label">Kuinka saa tulla kohteeseen?</label>
                </div>
                <div class="col-6 col-lg-4 pt-2">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="entry-1" value="1" v-model="edit_data.site_entry">
                    <label class="custom-control-label" for="entry-1">Soita ja sovi</label>
                  </div>
                </div>
                <div class="col-6 col-lg-4 pt-2">
                  <div class="custom-control custom-radio">
                    <input type="radio" class="custom-control-input" id="entry-2" value="2" v-model="edit_data.site_entry">
                    <label class="custom-control-label" for="entry-2">Yleisavaimella</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12 col-lg-6">
                  <label class="form-label">Sovitun käynnin ajankohta</label>
                  <input type="datetime-local" v-model="edit_data.site_entry_date" class="form-control" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-12">
                  <label class="form-label">Lemmikkieläimiä tai muuta huomioitavaa?</label>
                </div>
                <div class="col-6 col-lg-3 pt-2">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="pets" value="1" v-model="edit_data.site_pets">
                    <label class="custom-control-label" for="pets">Kyllä</label>
                  </div>
                </div>
                <div class="col-6" v-if="edit_data.site_pets == 1">
                  <input type="text" v-model="edit_data.site_other" class="form-control" />
                </div>
              </div>

              <div class="form-group row">
                <div class="col-12">
                  <label class="form-label">Liitteet</label>
                </div>
                <div class="col-12 pt-2">
                  <p v-if="typeof edit_data.new_files != 'undefined'">{{ edit_data.new_files.length }} liitettä.</p>
                  <p v-if="typeof edit_data.files != 'undefined' && edit_data.files.length == 0">Ei liitteitä.</p>
                  <div class="row file-list" v-if="typeof edit_data.files != 'undefined' && edit_data.files.length > 0">
                    <div class="col-6 col-md-4 file" v-for="(file) in edit_data.files" :key="file.id" v-on:click="showImage(['/api/files/' + file.id])">
                      <img :src="['/api/files/' + file.id]" :alt="file.filename" />
                      <p class="blue">{{ file.fullname }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="dragarea" @drop.prevent="dropFile" @dragover.prevent>
                    <p><i class="fas fa-upload"></i></p>
                    <p>Raahaa tiedosto tähän<br/>tai<br/><a href="#" @click.stop.prevent="$refs.file.click()">selaa tiedostoja</a></p>
                  </div>
                  <div class="d-none"><input type="file" @change="selectFile" ref="file" multiple="multiple"></div>
                </div>
              </div>

						</div>

            <div class="col-12 col-lg-6">
              <div class="text-right small pb-3" v-if="typeof edit_data.id != 'undefined'">
                <a href="#" v-on:click.stop.prevent="newitem">Luo uusi huoltotyö</a>
              </div>
              <div class="form-group text-right">
                <select class="ticket-status" :class="[ 'status-' + edit_data.status ]" v-model="edit_data.status" v-if="typeof edit_data.id != 'undefined'" :disabled="edit_data.status == 4 ? true : false">
                  <option value="1">Uusi</option>
                  <option value="2">Kesken</option>
                  <option value="3">Valmis</option>
                  <option value="4" v-if="edit_data.status == 4">Toistuva</option>
                </select>

                <button type="button" class="btn btn-delete" v-if="typeof edit_data.id != 'undefined'" @click="confirmDelete = true"><i class="fas fa-trash"></i> POISTA</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id != 'undefined'" v-on:click="canceledit()">TAKAISIN</button>
                <button type="button" class="btn btn-light btn-lg ml-2" v-if="typeof edit_data.id == 'undefined'" v-on:click="canceledit()">PERUUTA</button>
                <button type="submit" class="btn btn-new btn-lg ml-2"><i class="fas fa-save"></i> TALLENNA</button>
              </div>

              <div class="form-group mb-0" v-if="typeof edit_data.id != 'undefined' && edit_data.status == 4">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="check_done" value="1" v-model="edit_data.check_done">
                  <label class="custom-control-label" for="check_done">Kuittaa ( {{ edit_data.next_check | formatDateTime }} )</label>
                </div>
              </div>

              <div class="mt-5 form-group gray-box mt-5"  v-if="typeof edit_data.id != 'undefined'">
                <div class="row">
                  <div class="form-group col-12">
                    <label class="form-label">Viimeisin muokkaus:</label>
                    <p class="mb-0">{{ edit_data.updated_by_name }} {{ edit_data.updated | formatDateTime }}</p>
                  </div>

                  <div class="form-group col-12">
                    <label class="form-label">Seloste (julkinen)</label>
                    <textarea v-model="edit_data.summary" class="form-control"></textarea>
                  </div>

                  <div class="form-group col-12">
                    <label class="form-label">Työseloste (sisäinen)</label>
                    <div class="log-container" v-if="typeof edit_data.log != 'undefined' && edit_data.log.length > 0">
                      <p v-for="(log) in edit_data.log" :key="log.id" ><span class="from">{{ log.fullname }} {{ log.created | formatDateTime }}</span>{{ log.summary }}</p>
                    </div>
                    <textarea v-model="edit_data.summary_internal" class="form-control short"></textarea>
                  </div>

                  <div class="form-group col-6">
                    <label class="form-label">Kilometrit (sisäinen)</label>
                    <input type="text" v-model="edit_data.distance" class="form-control" />
                  </div>

                  <div class="form-group col-6">
                    <label class="form-label">Reitti (sisäinen)</label>
                    <input type="text" v-model="edit_data.route" class="form-control" />
                  </div>
                </div>
              </div>

            </div>

					</div>
				</form>

				<b-overlay :show="confirmDelete" variant="white" no-center no-wrap>
					<template #overlay>
						<div ref="dialog" tabindex="-1" role="dialog" aria-modal="false" aria-labelledby="form-confirm-label" class="text-center p-5" >
							<p><strong id="form-confirm-label">Haluatko varmasti poistaa huoltoilmoituksen "{{ edit_data.title }}" ?</strong></p>
							<div class="text-center">
								<b-button variant="outline-danger" class="mr-3" @click="confirmDelete = false">Peruuta</b-button>
								<b-button variant="outline-success" @click="senddelete()">OK</b-button>
							</div>
						</div>
					</template>
				</b-overlay>

        <b-modal id="image-modal" size="xl" centered v-model="imageModal"><img :src="imageModalImg" /></b-modal>

			</div>
		</div>

  </div>

</template>

<script>
export default {
  data: function () {
		return {
			loading: true,
			error: false,
			items: [],
			sites: [],
      areas: [],
      types: [],
      employees: [],
      filters: {
        status: '',
        site: '',
        type: '',
        area: '',
        assigned_to: '',
        site_entry_date: '',
        checkup: false,
      },
      statuses: [ { id: 1, name: 'Uusi' }, { id: 2, name: 'Kesken' }, { id: 3, name: 'Valmis' }, { id: 4, name: 'Toistuva' } ],
			edit: false,
			edit_data: [],
      files: [],
      confirmDelete: false,
			sending: false,
      uploading: false,
      progress: 0,
			visible: 0,
			perpage: 10,
			pages: 0,
			page: 0,
			sort: 'created:desc',
      imageModal: false,
      imageModalImg : ''
    }
	},
	created: function () {
		var vm = this;
		this.axios.get('/api/sites?limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.sites = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    this.axios.get('/api/filters?limit=500')
		.then(function (response) {
			if(response.status == 200) {
        (response.data.results).forEach(filter => {
          if(filter.type == 1) vm.areas.push(filter);
          else if(filter.type == 2) vm.types.push(filter);
        })
			}
		})
		.catch(function (error) {
			console.log(error);
		})
		this.axios.get('/api/users?type=employees&limit=500')
		.then(function (response) {
			if(response.status == 200) {
				vm.employees = response.data.results;
			}
		})
		.catch(function (error) {
			console.log(error);
		})
    vm.load();
    if(typeof vm.$route.query.id != 'undefined') {
      if(vm.$route.query.id == 'new') vm.newitem();
			else vm.edititem(this.$route.query.id);
		}
	},
	methods: {
		load: function () {
			var vm = this;
      vm.error = false;
			vm.loading = true;
			var sort = vm.sort.split(':');
			this.axios.get('/api/tickets?limit=' + vm.perpage + '&start=' + (vm.page * vm.perpage) + '&sort=' + sort[0] + '&dir=' + sort[1] + '&filters=' + JSON.stringify(vm.filters))
			.then(function (response) {
				if(response.status == 200) {
					vm.loading = false;
					vm.pages = Math.ceil(response.data.total / vm.perpage);
					vm.items = response.data.results;
					vm.visible = response.data.results.length;
				} else {
					vm.error = response.data.message;
				}
			})
			.catch(function (error) {
				vm.error = error;
				vm.items = [];
				vm.pages = 0;
				vm.loading = false;
			})
		},
    getStatus: function(s_id) {
      if(s_id != null) {
        var vm = this;
        var find = parseInt(s_id);
        const result = vm.statuses.find( ({ id }) => id === find );
        if(result) return result.name;
      }
      return s_id;
    },
    getSite: function(s_id) {
      if(s_id != null) {
        var find = parseInt(s_id);
        const result = this.sites.find( ({ id }) => id === find );
        if(result) return result.name;
      }
      return s_id;
    },
    showImage: function(image) {
      this.imageModalImg = image;
      this.imageModal = true;
    },
		showpage: function (id) {
			this.page = id;
		},
		edititem: function (id) {
      var vm = this;
      vm.edit = true;
      vm.sending = true;

      this.axios.get('/api/tickets/' + id)
      .then(function (response) {
        if(response.data.success) {
          vm.edit_data = response.data.object;
          if(response.data.object.site_entry_date != null) vm.edit_data.site_entry_date = vm.$options.filters.formatInputDateTime(response.data.object.site_entry_date);
          vm.sending = false;
        } else {
          vm.$parent.makeToast('danger', 'Huoltotyön lataus', 'Epäonnistui!');
          vm.edit = false;
          vm.sending = false;
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.$parent.makeToast('danger', 'Huoltotyön lataus', 'Epäonnistui!');
        vm.edit = false;
        vm.sending = false;
      })

		},
		canceledit: function () {
			this.edit = false;
		},
		newitem: function () {
			this.edit = true;
			this.edit_data = { title: '', description:'', type: '', site_id: '', new_files: [] }
		},
    dropFile: function (e) {
      let droppedFiles = e.dataTransfer.files;
      if(!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
      this.uploadFiles();
    },
    selectFile: function() {
      for( var i = 0; i < this.$refs.file.files.length; i++ ){
				let file = this.$refs.file.files[i];
        this.files.push(file);
			}
      this.uploadFiles();
    },
    uploadFiles: function() {
      var vm = this;

      if(vm.files.length == 0) return;

      vm.uploading = true;
      vm.progress = 0;

      let formData = new FormData();
      if(typeof vm.edit_data.id != 'undefined') formData.append('ticket_id', vm.edit_data.id);
      vm.files.forEach((f,x) => {
        formData.append('file'+(x+1), f);
      });

      this.axios.post('/api/files', formData, {
				headers: {
          'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					vm.progress = Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
				}
			})
			.then(function (response) {
        if(response.data.success) {
          vm.$parent.makeToast('success', 'Tiedostojen siirto', 'Onnistui!');
          vm.uploading = false;
          vm.files = [];
          if(typeof vm.edit_data.id != 'undefined') { vm.edititem(vm.edit_data.id); }
          else {
            response.data.object.forEach(element => vm.edit_data.new_files.push(element));
            //vm.edit_data.files.push(response.data.object);
          }
        } else {
          vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
          vm.uploading = false;
          vm.files = [];
        }
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Tiedostojen siirto', 'Epäonnistui!');
				vm.uploading = false;
        vm.files = [];
			});

    },
		saveedit: function () {
			var vm = this;
			if(!vm.sending) {
				vm.sending = true;
				var method = 'put';
				if(typeof vm.edit_data.id == 'undefined') method = 'post';
				this.axios({
					method: method,
					url: '/api/tickets',
					data: vm.edit_data
				})
				.then(function (response) {
					if(response.data.success) {
            vm.edit_data = response.data.object;
            if(response.data.object.site_entry_date != null) vm.edit_data.site_entry_date = vm.$options.filters.formatInputDateTime(response.data.object.site_entry_date);
            if(typeof vm.edit_data.id != 'undefined') vm.edititem(vm.edit_data.id);

						vm.$parent.makeToast('success', 'Huoltotyön tallennus', 'Onnistui!');
						vm.load();
					} else {
						vm.$parent.makeToast('warning', 'Huoltotyön tallennus', 'Täytä pakolliset kentät!');
					}
					vm.sending = false;
				})
				.catch(function () {
					vm.sending = false;
					vm.$parent.makeToast('danger', 'Huoltotyön tallennus', 'Epäonnistui!');
				})
			}
		},
		senddelete: function () {
			var vm = this;
      vm.sending = true;
      vm.confirmDelete = false;
			this.axios({
				method: 'delete',
				url: '/api/tickets',
				data: vm.edit_data
			})
			.then(function (response) {
				if(response.data.success) {
					vm.$parent.makeToast('success', 'Huoltotyön poisto', 'Onnistui!');
					vm.load();
					vm.edit = false;
				} else {
					vm.$parent.makeToast('danger', 'Huoltotyön poisto', 'Epäonnistui!');
				}
        vm.sending = false;
			})
			.catch(function () {
				vm.$parent.makeToast('danger', 'Huoltotyön poisto', 'Epäonnistui!');
        vm.sending = false;
			})
    }
	},
	watch: {
		page: function () {
			this.load();
		},
    sort: function () {
			this.load();
		},
	},
}
</script>
